<template>
  <div>
    <div
      style="background-image: linear-gradient(180deg, #0272c91a 0%, #ffffff00 100%);"
    >
      <div class="about-us">
        <div class="title-text">关于我们</div>
        <div class="content-text">
          鸿象是成都鸿象科技有限公司旗下的软件产品，创新性为企业搭建专属全要素实景地图，让企业数据更高效的可视化呈现，赋能企业感知态势、洞察趋势。
        </div>
        <div class="img-box">
          <img src="@/assets/images/guanyuwomen.png" alt="" />
          <img src="@/assets/images/guanyuwomen2.png" alt="" />
        </div>
      </div>
    </div>

    <!-- 联系我们 -->
    <contact-us></contact-us>
  </div>
</template>

<script>
import { caseTag, caseGet } from "@/api/request";

import ContactUs from "../home/ContactUs";

export default {
  name: "aboutUs", // 关于我们

  components: {
    ContactUs,
  },
  data() {
    return {};
  },

  methods: {},
};
</script>

<style lang="less" scoped>
div {
  box-sizing: border-box;
}
.about-us {
  width: 1180px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  padding: 80px 0;
  .title-text,
  .content-text {
    font-family: HarmonyOS_Sans;
    font-weight: SC;
    font-size: 36px;
    color: #000000;
    letter-spacing: 0;
  }
  .content-text {
    margin-top: 40px;
    font-size: 16px;
    color: #393c40;
  }

  .img-box {
    margin-top: 60px;
    display: flex;
    gap: 40px;
    justify-content: center;
  }
}
</style>
